var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "full-height": "" } },
    [
      _vm.loading ? _c("Loading", { attrs: { text: _vm.text } }) : _vm._e(),
      _vm._v(" "),
      _c("Header", { attrs: { htitle: _vm.htitle } }),
      _vm._v(" "),
      _c(
        "main",
        [
          _c(
            "div",
            { staticClass: "product__scroll" },
            _vm._l(_vm.product_list, function (product, index) {
              return product.view
                ? _c("Product", { key: index, attrs: { product: product } })
                : _vm._e()
            }),
            1
          ),
          _vm._v(" "),
          _vm.canShowMore
            ? _c(
                "div",
                { staticStyle: { "margin-bottom": "12px" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        width: "100%",
                        "text-align": "center",
                        "border-radius": "8px",
                        border: "1px solid var(--grayColor)",
                      },
                      on: { click: _vm.loadShopProducts },
                    },
                    [_vm._v("もっと見る")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-button", {
            staticClass: "btn-scroll-top",
            attrs: { icon: "el-icon-top" },
            on: { click: _vm.scrollTop },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }